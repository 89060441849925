class RealNumber {
    constructor(number, initialization) {
        this.integerPart = initialization?.integerPart || 0;
        this.decimalPart = initialization?.decimalPart || 0;
        this.thousandSeparator = initialization?.thousandSeparator || '';
        this.decimalSeparator = initialization?.decimalSeparator || '.';
        this.float = initialization?.float || 0;
        if (number === null || number === undefined) {
            number = 0;
        }
        if (typeof number === 'string') {
            if (Number.isNaN(parseFloat(number))) {
                number = 0;
            } else {
                number = this.removeSeparators(number);
            }
        }
        this._stringFormat = '0';
        this._numberFormat = 0;
        this.splitParts(number);
        this.stringFormat(number);
        this.numberFormat();
    }

    removeSeparators(value) {
        if (!this.float) {
            return value.replaceAll(this.thousandSeparator, '');
        }
        value = value.split('');
        let isBeenLast;
        value.forEach((symbol, index) => {
            if (/[^0-9]/.test(symbol) && symbol !== '-') {
                value[index] = '';
                isBeenLast = index;
            }
        });
        if (isBeenLast) {
            value[isBeenLast] = '.';
        }
        return value.join('');
    }

    splitParts(number) {
        this.decimalPart = (parseFloat(number) - parseInt(number)).toFixed(this.float);
        this.integerPart = parseInt(parseInt(number) + parseFloat(this.decimalPart)).toString();
    }

    stringFormat(number) {
        if (typeof number === 'string') {
            if (this.integerPart === '0' && number[0] === '-') {
                this.integerPart = '-0';
            }
        } else if (this.integerPart === '0' && number < 0) {
            this.integerPart = '-0';
        }
        let integer = parseInt(this.integerPart).toLocaleString('en-US').replaceAll(',', this.thousandSeparator);
        let decimal = this.decimalPart.replace('0', '');
        if (parseInt(decimal) === 1) {
            this._stringFormat = integer + Number(0).toFixed(this.float).replace('0.', '.');
            return true;
        }
        this._stringFormat = integer + decimal.replace('.', this.decimalSeparator).replace('-', '');
    }

    numberFormat() {
        if (parseFloat(this.decimalPart) < 0) {
            this.decimalPart = Math.abs(parseFloat(this.decimalPart)).toString();
        }
        let number = parseFloat(this.integerPart + this.decimalPart);
        let decimal;
        if (number >= 0) {
            decimal = number - parseInt(number);
            this._numberFormat = parseFloat(parseFloat(parseInt(this.integerPart) + decimal).toFixed(this.float));
        } else {
            decimal = Math.abs(number) - Math.abs(parseInt(number));
            this._numberFormat = parseFloat(parseFloat(parseInt(this.integerPart) - decimal).toFixed(this.float));
        }
    }
}

export default RealNumber;
